import API from "@utils/request";
import { PAGE_SIZE } from "@config";

const USER = "/education/plaedumessagesend/";

/**
* 获取平台消息通知表列表（分页）
* @author 四川创企
* @DateTime 2023/05/06
* @param {object} params
* @param {object} pages
*/
export async function getPlaMessageList(params, pages) {
    try {
        return await API.get(USER + "list", {
            params: {
                ...params,
                ...pages,
            },
            headers: { isLoading: true },
        });
    } catch (error) {
        return error;
    }
}

/**
* 获取平台消息通知表详情
* @author 四川创企
* @DateTime 2023/05/06
* @param {number} id
*/
export async function getPlaMessageInfo(id) {
    try {
        return await API.post(USER + "info",{
            pledu_messen_id:id
        });
    } catch (error) {
        return error;
    }
}
/**
* 编辑平台消息通知表
* @author 四川创企
* @DateTime 2023/05/06
* @param {object} params
*/
export async function messageEdit(params) {
    try {
        return await API.post(USER + "edit", params);
    } catch (error) {
        return error;
    }
}

/**
* 新增平台消息通知表
* @author 四川创企
* @DateTime 2023/05/06
* @param {object} params
*/
export async function messageAdd(params) {
    try {
        return await API.post(USER + "add", params);
    } catch (error) {
        return error;
    }
}
/**
* 刪除平台消息通知表
* @author 四川创企
* @DateTime 2023/05/06
* @param {number} id
*/
export async function deletePlaMessage(id) {
    try {
        return await API.post(USER + "delete", {
            pledu_messen_id:id,            
        });
    } catch (error) {
        return error;
    }
}
/**
* 推送消息
* @author 四川创企
* @DateTime 2023/05/06
* @param {number} id
*/
export async function publishPlaMessage(id) {
    try {
        return await API.post(USER + "publish", {
            pledu_messen_id:id,
            
        });
    } catch (error) {
        return error;
    }
}




// 用户详情
export async function getInfo(params) {
  // try {
  //   return await API.post(USER + "vue-element-admin/user/info", params);
  // } catch (error) {
  //   return error;
  // }
  return {
    code: '000000',
    data:  {
      id: 1, // id
      name: 'admin',  // 前端路由名称
      avatar: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif?imageView2/1/w/80/h/80',  
      roles:"管理员"
    },
  }
}

