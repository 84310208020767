/*
 * @Author: Dyf
 * @Date: 2023-05-19
 * @Description: 数据统计模块接口
 */
import API from "@utils/request";

/*******
 * @Descripttion: 学校列表
 * @Author: Dyf
 * @return {*}
 */
export async function $schoolList() {
  try {
    return await API.post("/education/schschool/getkeyval", {
      key: "scsch_name",
      value: "scsch_id",
    });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 资源库统计数据
 * @Author: Dyf
 * @param {number} scsch_id 学校id
 * @return {*}
 */
export async function $statisticsResource(scsch_id) {
  try {
    return await API.post("/education/resource/get_data", { scsch_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 数据分析（学校)
 * @Author: Dyf
 * @param {number} scsch_id 学校id
 * @return {*}
 */
export async function $statisticsSchool(scsch_id) {
  try {
    return await API.post("/education/resource/get_analysis_data", { scsch_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 数据分析（班级）
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $statisticsClass(params) {
  try {
    return await API.post("/education/resource/get_class_analysis", params);
  } catch (error) {
    return error;
  }
}
