<style scoped lang="scss">
    .curriculum {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .look-btn {
        width: 60px;
        padding: 0;
        height: 30px;
        line-height: 30px;
    }

    .curriculum-search {
        flex: none;
    }

    .curriculum-table {
        flex: 1;
        overflow: hidden;

        .table_center {
            height: calc(100% - 60px);
            overflow-y: scroll;
        }
    }

    // 分页
    .paging {
        padding: 10px 0;
        text-align: right;
    }

    // 新增课程
    .new-courses {
        width: 100%;
        height: 68px;
        line-height: 68px;
        background-color: #f6f7fc;
        font-size: 16px;
        font-weight: 600;
        color: #1b1b1b;
        position: relative;
        text-indent: 36px;
    }

    .new-courses::after {
        content: "";
        width: 5px;
        height: 18px;
        background-color: #6340c8;
        border-radius: 2.5px;
        position: absolute;
        top: 26px;
        left: 25px;
    }

    .curriculum-search {
        margin-top: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    ::v-deep {
        .curriculum-search .el-form {
            flex: 1;
            display: flex;
            justify-content: center;
        }

        .newcourses .el-dialog {
            border-radius: 10px;
            overflow: hidden;
        }

        .el-dialog__header {
            padding: 0;
        }

        .el-dialog__body {
            padding: 0;
        }

        .curriculum-search .el-input--medium .el-input__inner {
            width: 100%;
            // height: 50px;
            // line-height: 50px;
            background: #f1f2f8;
            border-radius: 10px;
            border: none;
            text-indent: 30px;
        }

        // .curriculum-search .el-form-item--medium .el-form-item__label {
        //   line-height: 50px;
        // }
        .curriculum-search .el-form--inline .el-form-item {
            margin-right: 19px;
        }
    }

    // 状态
    .normal {
        font-size: 14px;
        color: #40c84a;
    }

    .unusual {
        font-size: 14px;
        color: #e53f32;
    }

    .new-courses-content {
        width: 1211px;
        padding: 29px 60px 0;
        box-sizing: border-box;
    }

    // 新增课程
    ::v-deep {
        .new-courses-content .el-input--medium .el-input__inner {
            height: 50px;
            line-height: 50px;
            width: 393px;
            background: #f6f7fa;
            border: none;
            border-radius: 10px;
        }

        .new-courses-content .title.el-input--medium .el-input__inner {
            width: 668px;
        }

        .new-courses-content .el-form-item--medium .el-form-item__label {
            line-height: 46px;
        }

        .el-dialog .el-dialog__body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    // 文本框设置
    ::v-deep {
        .el-input--medium .el-input__icon {
            line-height: 50px;
            font-size: 22px;
            text-indent: 20px;
        }
    }

    // tabs
    .substance-tabs-ul {
        display: flex;
        justify-content: center;
        padding: 30px 0;
        border-bottom: 1px solid #d7d5eb;

        .substance-tabs-li {
            width: 184px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            color: #353535;
            font-weight: 600;
            border-right: 2px solid #dcdee6;
            position: relative;
            cursor: pointer;
        }

        .active {
            color: #6340c8;
        }

        .active::after {
            content: "";
            width: 39px;
            height: 6px;
            background-color: #58419c;
            border-radius: 2.5px;
            position: absolute;
            bottom: -30px;
            left: 73px;
        }

        .substance-tabs-li:last-child {
            border-right: none;
        }
    }

    // 富文本框
    ::v-deep {
        .editor .text {
            min-height: 168px !important;
        }

        .el-form-item--medium .el-form-item__content {
            // line-height: 50px;
        }

        .el-upload--picture-card {
            width: 102px;
            height: 102px;
            line-height: 112px;
            background: #f0efef !important;
            border-radius: 2px;
            border: none;
        }

        .el-upload--picture-card .el-icon-plus::before {
            content: "\e8bc" !important;
            font-family: "iconfont";
            font-size: 30px;
            color: #d0d0d0;
        }

        .el-upload-list--picture-card .el-upload-list__item {
            width: 102px;
            height: 102px;

            margin: 0 8px 0;
        }
    }
</style>

<template>
    <section class="curriculum">
        <div class="curriculum-search">
            <el-form :inline="true" :model="searchform" class="demo-form-inline">
                <el-col :span="12">
                    <el-input v-model="searchform.queryword" prefix-icon="el-icon-search"
                              placeholder="请输入关键字"></el-input>
                </el-col>

                <!-- <el-form-item>
                  <el-input
                    v-model="searchform.shool"
                    prefix-icon="el-icon-search"
                    placeholder="请输入关键字"
                  ></el-input>
                </el-form-item> -->

                <el-form-item style="margin-left: 20px">
                    <el-button type="primary" @click="onSubmit" class="lightpurple-btn">搜索</el-button>
                    <el-button type="primary" @click="handleadd" class="lightyellow-btn">新增<i class="el-icon-plus"
                                                                                              style="font-size: 12px"></i>
                    </el-button>
                </el-form-item>
            </el-form>
        </div>

        <div class="curriculum-table">
            <template>
                <div class="table_center">
                    <el-table :data="tableData" style="width: 100%" :header-cell-style="{
            background: '#E8EAF3',
            color: '#1a1a1b',
            'text-align': 'center',
            fontWeight: 500,
          }">
                        <el-table-column prop="pledu_messen_id" label="ID"></el-table-column>
                        <el-table-column prop="pledu_messen_title" label="文章标题"></el-table-column>
                        <el-table-column prop="pledu_messen_status" label="状态">
                            <template slot-scope="scope">
                                <p>
                                    {{
                                    showpledu_messen_status(scope.row.pledu_messen_status)
                                    }}
                                </p>
                            </template>
                        </el-table-column>

                        <el-table-column prop="create_time" label="添加时间"></el-table-column>
                        <el-table-column prop="create_name" label="创建人"></el-table-column>

                        <el-table-column label="操作" width="320">
                            <template slot-scope="scope">
                                <el-button class="blue-btn look-btn" @click="handelinfo(scope.row)">详情</el-button>
                                <el-button class="green-btn look-btn" v-if="scope.row.pledu_messen_status == 10"
                                           @click="handeledit(scope.row, 'info')">编辑
                                </el-button>
                                <el-button class="yellow-btn look-btn" v-if="scope.row.pledu_messen_status == 10"
                                           @click="handelpublish(scope.row)">发布
                                </el-button>
                                <el-button class="red-btn look-btn" v-if="scope.row.pledu_messen_status == 10"
                                           @click="handeldelete(scope.row)">删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

                <div class="paging">
                    <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage"
                                   :page-size="searchform.pageSize" next-text="下一页" layout=" pager, next, jumper"
                                   :total="total">
                    </el-pagination>
                </div>
            </template>
        </div>


        <!-- 新增课程 -->
        <el-dialog width="1211px" class="newcourses" :visible.sync="dialogTableVisible" @close="handelanle">

            <div class="new-courses">新增/编辑消息</div>

            <div class="new-courses-content">
                <el-form label-width="80px" ref="newcoursesData" :rules="messageRules" :model="newcoursesData">

                    <el-form-item label="消息标题" prop="pledu_messen_title">
                        <el-input v-model="newcoursesData.pledu_messen_title" class="title" placeholder="请输入文章标题"
                                  :disabled="infoDisabled" maxlength="100"></el-input>
                    </el-form-item>

                    <el-form-item label="内容" class="editor" prop="pledu_messen_content">
                        <div class="ueditor">
                            <!-- <try-tinymce v-model="newcoursesData.pledu_messen_content" :showEditor="dialogTableVisible" :width="800" :height="300"></try-tinymce> -->

                            <try-editor v-model="newcoursesData.pledu_messen_content"/>
                        </div>
                    </el-form-item>


                    <el-form-item label="学校" prop="scsch_ids">
                        <el-select v-model="newcoursesData.scsch_ids" multiple placeholder="请选择"
                                   :disabled="infoDisabled">
                            <el-option v-for="item in schoolList" :key="item.value" :label="item.key"
                                       :value="Number(item.value)">
                            </el-option>
                        </el-select>
                    </el-form-item>


                    <el-form-item style="
              margin-top: 20px;
              padding-top: 26px;
              text-align: right;
              border-top: 1px solid #1b1111;
            ">
                        <el-button type="primary" class="lightgrey-btn" @click="handelanle">取消</el-button>
                        <el-button class="lightpurple-btn" type="primary" v-if="type != 'info'" @click="submitData"
                                   :disabled="infoDisabled" :loading="loadingSubut">确定
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>

        <dialog-bar v-model="sendVal" type="danger" title="删除" content="是否确认删除当前行消息" v-on:cancel="clickCancel()"
                    @danger="clickDanger()" @confirm="clickConfirm()" dangerText="确定"></dialog-bar>

        <!-- 发布弹窗 -->
        <dialog-bar v-model="pushVal" type="danger" title="发布" content="是否发布当前消息" v-on:cancel="clickpushCancel()"
                    @danger="publish()" dangerText="确定"></dialog-bar>
    </section>
</template>

<script>

    import wangeditor from "@/components/wangeditor";
    import {$schoolList} from "@api/dyf_statistics"
    import {
        getPlaMessageList,
        getPlaMessageInfo,
        messageAdd,
        messageEdit,
        deletePlaMessage,
        publishPlaMessage
    } from "@/api/information";

    export default {
        name: "",
        components: {
            wangeditor,
            dialogBar: () => import("@/components/configbox.vue"), //引入弹框组件
        },
        data() {
            return {
                dialogImageUrl: "",
                type: "",
                dialogVisible: false,//删除弹窗
                pushdialogVisible: false,//发布弹窗
                sendVal: false,//删除弹窗
                pushVal: false,//发布弹窗
                notice: 1,
                schoolList: [],//学校数据源
                loadingSubut: false,
                deleteId: 0,//删除id
                infoDisabled: false,//表单是否可编辑
                messageRules: {
                    pledu_messen_title: [
                        {required: true, message: "请输入标题", trigger: "blur"},
                    ],
                    pledu_messen_content: [
                        {required: true, message: "请输入通知内容", trigger: "change"},
                    ],

                    scsch_ids: [
                        {
                            type: "array",
                            required: true,
                            message: "请选择接收群体",
                            trigger: "change",
                        },
                    ],
                },
                currentPage: 1,
                total: 120,
                pledu_messen_status_option: [],//状态下拉数据源
                searchform: {
                    pageIndex: 1,
                    pageSize: 10
                },

                // 表格数据
                tableData: [],
                // 新增课程
                dialogTableVisible: false,
                newcoursesData: {
                    pledu_messen_content: '',
                    pledu_messen_title: '',
                    scsch_ids: '',
                    name: "",
                    status: false,
                },
            };
        },

        mounted() {
            this.getdata();
            this.getSchoolList();
        },
        methods: {
            //取消选中
            handelanle() {

                this.loadingSubut = false;
                this.dialogTableVisible = false
                this.$nextTick(() => {
                    this.$refs.newcoursesData.resetFields();
                })


            },
            handelupdateschool() {

            },
            /** 获取可选学校列表 */
            async getSchoolList() {
                this.dataLoad = true;
                let {data} = await $schoolList();
                this.schoolList = [...data] || [];
                if (!this.$isEmpty(data)) {
                    this.schoolId = data[0].value;
                } else {
                    this.dataLoad = false;
                }
                this.$forceUpdate();
            },
            //状态字典方法
            showpledu_messen_status(val) {
                let resKey;
                this.pledu_messen_status_option.forEach((element) => {
                    if (element.key == val) {
                        resKey = element.value;
                    }
                });
                return resKey;
            },
            async getdata() {
                /*重置状态 */
                this.pledu_messen_status_option = [
                    {key: 10, value: '待发布'},
                    {key: 20, value: '已发布'}
                ];

                const pages = {
                    pageindex: this.currentPage,
                    pagesize: this.size,
                };
                /*查询列表信息*/
                const {
                    data: resdata
                } = await getPlaMessageList(this.searchform, pages);
                let res = resdata;

                this.tableData = res.data; //数据
                this.total = res.allcount; //总数
                this.currentPage = res.pageindex; //当前页
                this.$forceUpdate();
            },
            handleRemove(file, fileList) {
                console.log(file, fileList);
            },
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
                console.log(this.dialogImageUrl, ">>>>.");
            },

            clickCancel() {
                this.deleteId = 0;
            },
            clickpushCancel() {
                this.publishId = 0;
            },
            //删除消息
            async clickDanger() {
                let res = {};
                res = await deletePlaMessage(this.deleteId);
                if (res.status == 200) {
                    this.$message.success("删除成功");

                } else {
                    this.$message.error("删除失败");
                }
                this.dialogTableVisible = false;
                this.deleteId = 0;
                this.getdata();
            },
            //删除按钮显示
            handeldelete(row) {
                this.sendVal = true;
                this.deleteId = row.pledu_messen_id;
            },
            /*新增*/
            async handleadd() {
                this.dialogTableVisible = true;
                this.infoDisabled = false;
                this.type = 'add';


                this.$nextTick(() => {
                    this.$refs.newcoursesData.clearValidate();
                    this.$forceUpdate();

                })
            },

            /* 详情*/
            async handelinfo(row) {
                this.infoDisabled = true;

                /*查询消息详情*/
                const {data: resData} = await getPlaMessageInfo(row.pledu_messen_id)
                this.newcoursesData = {...resData};
                this.dialogTableVisible = true;
                this.type = 'info';

            },
            /* 编辑*/
            async handeledit(row) {
                this.infoDisabled = false;

                /*查询消息详情*/
                const {data: resData} = await getPlaMessageInfo(row.pledu_messen_id)
                this.newcoursesData = resData;
                this.dialogTableVisible = true;
                this.type = 'edit';


            },
            //发布消息
            async publish() {
                let res = {};
                res = await publishPlaMessage(this.publishId);
                if (res.status == 200) {
                    this.$message.success("发布成功");
                    this.dialogTableVisible = false;
                    this.getdata();
                }
            },
            /*保存信息 */
            async submitData() {
                this.loadingSubut = true;
                this.$refs.newcoursesData.validate(async (valid) => {
                    if (valid) {
                        let params = {};
                        let res = {};
                        if (this.type == "add") {
                            params = {
                                pledu_messen_title: this.newcoursesData.pledu_messen_title,
                                pledu_messen_content: this.newcoursesData.pledu_messen_content,
                                pledu_messen_status: this.newcoursesData.pledu_messen_status,
                                scsch_ids: this.newcoursesData.scsch_ids.join(","),
                            };
                            // params.plsch_messen_content = this.$refs.noticeContent.getUeditorContent();
                            res = await messageAdd(params);
                        } else {
                            params = {
                                pledu_messen_id: this.newcoursesData.pledu_messen_id,
                                pledu_messen_title: this.newcoursesData.pledu_messen_title,
                                pledu_messen_content: this.newcoursesData.pledu_messen_content,
                                pledu_messen_status: this.newcoursesData.pledu_messen_status,
                                scsch_ids: this.newcoursesData.scsch_ids.join(","),

                            }
                            res = await messageEdit(params);
                        }

                        if (res.status == 200) {
                            this.$message.success(this.type == "add" ? "添加成功" : "编辑成功");
                            this.dialogTableVisible = false;
                            this.getdata();
                        }
                        this.loadingSubut = false;
                    }
                    this.loadingSubut = false;
                });
            },
            onSubmit() {
                this.getdata();
            },
            //清除表单数据
            formdataClear() {

            },
            //发布消息弹窗
            handelpublish(row) {
                this.publishId = row.pledu_messen_id;
                this.pushVal = true;
            },
            handleClick() {
                this.$router.push({path: "/course/info"});
                // this.$router.push({path: '/login?url=' + this.$route.path});
            },

            // 修改当前
            handleCurrentChange() {
                this.currentPage = this.currentPage;

                this.getdata();
            },
        },
    };
</script>
